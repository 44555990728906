import * as React from "react";
import Cat from "../misc/cat";
import { NewLine, Help } from "../commands";
import userData from "../../data/userData.json";
import { IUserData } from "../../model/userData";
import { NoCommand } from "../commands/noCommand";
import Typist from "react-typist";
import { Menu } from "../common";

interface ILandingPageState {
  numberOfLine: number;
  displayEverything: {
    command: string;
    id?: number;
    displayInput?: boolean;
    absoluteCommand: string;
  }[];
}

export class LandingPage extends React.Component<{}, ILandingPageState> {
  userData: IUserData;

  constructor(props: ILandingPageState) {
    super(props);
    this.state = {
      numberOfLine: 0,
      displayEverything: [
        { command: "", id: 0, displayInput: true, absoluteCommand: "newLine" },
      ],
    };

    this.userData = userData;
  }

  information = {
    name: "Mohammed Rizwan Khan",
    commands: [
      {
        id: 10,
        name: "ls",
      },
      {
        id: 11,
        name: "cat",
      },
      {
        id: 12,
        name: "cls",
      },
      {
        id: 13,
        name: "help",
      },
    ],
    subDir: [
      {
        id: 20,
        name: "about.txt",
      },
      {
        id: 21,
        name: "education.txt",
      },
      {
        id: 22,
        name: "projects.txt",
      },
      {
        id: 23,
        name: "socials.txt",
      },
      {
        id: 24,
        name: "contact.txt",
      },
    ],
  };

  onKeyDown = (command: string, Tid: number) => {
    let absoluteCommand = command.split(" ");

    if (absoluteCommand[0] === "cls") {
      this.setState({
        numberOfLine: 0,
        displayEverything: [
          {
            command: "",
            id: 0,
            displayInput: true,
            absoluteCommand: "newLine",
          },
        ],
      });
    } else {
      this.setState(
        {
          displayEverything: [
            ...this.state.displayEverything.filter((l) => l.id !== Tid),
            {
              ...this.state.displayEverything.find((l) => l.id === Tid),
              command: command,
              absoluteCommand: absoluteCommand[0],
            },
          ],
        },
        () => this.handleClick()
      );
    }
  };

  // Adding a new line after executing a command
  handleClick = () => {
    const num = this.state.displayEverything.length + 1;
    this.setState({
      numberOfLine: this.state.numberOfLine + 1,
      displayEverything: [
        ...this.state.displayEverything,
        {
          command: "",
          id: num,
          displayInput: true,
          absoluteCommand: "newLine",
        },
      ],
    });
  };

  render(): JSX.Element {
    return (
      <div className="container">
        <div className="terminal">
          <p className="welcomeText copyright m-b-0">{this.userData.name}</p>
          <Typist cursor={{ show: false }}>
            <p className="welcomeText copyright m-t-0">
              {"Terminal On Web (TOW) from Rizwan"}
              <Typist.Backspace count={11} delay={1000} />
              &copy;{" "}
              <u>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://irizwan.com"
                >
                  {"irizwan"}
                </a>
              </u>
            </p>
          </Typist>
          <p className="welcomeText separator">
            ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: this.userData.welcomeText.replace(
                "{0}",
                this.userData.preferredName
              ),
            }}
            className="welcomeText"
          />

          {this.state.displayEverything.map((command) => {
            if (command.absoluteCommand === "newLine") {
              return (
                <NewLine
                  key={command.id as number}
                  onKeyDown={this.onKeyDown}
                  command={command}
                  displayInput={command.displayInput}
                ></NewLine>
              );
            } else if (command.absoluteCommand === "ls") {
              return (
                <Menu
                  key={command.id}
                  command={command}
                  items={this.information.subDir}
                ></Menu>
              );
            } else if (command.absoluteCommand === "help") {
              return <Help />;
            } else if (command.absoluteCommand === "cat") {
              return <Cat key={command.id} line={command}></Cat>;
            }
            return (
              <NoCommand
                key={command.id}
                command={command.command.trim().split("$|$")[0]}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
