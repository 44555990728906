import { LandingPage } from "./app/components/landingPage";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Switch } from "react-router-dom";

function App(): JSX.Element {
  return (
    <div className="App">
      <Helmet
        defaultTitle="profile-geek"
        titleTemplate="%s - profile-geek"
      ></Helmet>
      <BrowserRouter>
        <Switch>
          <Route
            path="/"
            render={(): JSX.Element => {
              return (
                <code>
                  <LandingPage />
                </code>
              );
            }}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
