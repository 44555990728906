import * as React from "react";
export interface IMenuProps {
  command: {
    command: string;
    id?: number;
    displayInput?: boolean;
    absoluteCommand: string;
  };
  items: {
    id: number;
    name: string;
  }[];
}

export class Menu extends React.Component<IMenuProps> {
  render(): JSX.Element {
    return (
      <React.Fragment>
        <p className="prompt">{this.props.command.absoluteCommand}</p>
        <ul>
          {this.props.items.map((item) => (
            <li key={item.id} className="result">
              {item.name}
            </li>
          ))}
        </ul>
      </React.Fragment>
    );
  }
}
