import React, { Component } from "react";
// import gif from "./eye-icon-animate.gif";

export class Cat extends Component {
  state = {
    command: this.props.line.command,
  };

  style = {
    align: "middle",
  };

  information = {
    about:
      "Hi, I’m Rizwan, a full-stack developer and product designer from India. I spend time thinking and writing about the web, design, and other stuff.",
    education: "Something excited will come up in here *_*",
    projects: [
      {
        projectName: "p1",
        liveDemo: "https://github.com/irizwankhan",
        linkToGithub: "https://github.com/irizwankhan",
      },
      {
        projectName: "p2",
        liveDemo: "https://github.com/irizwankhan",
        linkToGithub: "https://github.com/irizwankhan",
      },
    ],
    social: [
      {
        platform: "Github",
        link: "https://github.com/irizwankhan",
      },

      {
        platform: "Github",
        link: "https://github.com/irizwankhan",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <p className="prompt">{this.props.line.command} </p>
        {this.handelCd()}
      </React.Fragment>
    );
  }

  handelCd = () => {
    const navigation = this.state.command.split(" ")[1];
    if (navigation) {
      const lower = navigation.toLowerCase();
      if (lower === "about.txt") {
        return <p className="result">{this.information.about}</p>;
      } else if (lower === "education.txt") {
        return <p className="result">{this.information.education}</p>;
      } else if (lower === "education.txt") {
      } else if (lower === "projects.txt" || lower === "project.txt") {
        return (
          <React.Fragment>
            {this.information.projects.map((everyProject) => {
              return (
                <p className="result">
                  {everyProject.projectName}
                  <a
                    href={everyProject.liveDemo}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Live Link
                  </a>
                  <a
                    href={everyProject.linkToGithub}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* <img src={githubLogo} alt="GithubLink to Code" /> */}
                  </a>
                </p>
              );
            })}
          </React.Fragment>
        );
      } else if (
        lower === "socials.txt" ||
        lower === "social.txt" ||
        lower === "contact.txt"
      ) {
        return (
          <React.Fragment>
            {this.information.social.map((everySocial) => {
              return (
                <p className="result">
                  <a
                    href={everySocial.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {everySocial.platform}
                  </a>
                  <a
                    href="https://github.com/irizwankhan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* <img src={githubLogo} alt="GithubLink to Code" /> */}
                  </a>
                </p>
              );
            })}
          </React.Fragment>
        );
      } else {
        return <p className="result">Opps wrong input</p>;
      }
    } else {
      return <p className="result">Opps wrong input</p>;
    }
  };
}

export default Cat;
