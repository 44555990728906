import * as React from "react";
import data from "../../../data/userData.json";
import { IUserData } from "../../../model/userData";

export interface INoCommandProps {
  command: string;
}

export class NoCommand extends React.Component<INoCommandProps> {
  userData: IUserData;

  constructor(props: INoCommandProps) {
    super(props);

    this.userData = data;
  }

  render(): JSX.Element {
    return (
      <React.Fragment>
        <p className="prompt">{this.props.command} </p>
        {this.props.command?.trim() !== "" && (
          <ul>
            <li className="result error">
              {this.userData.noCommandL1.replace("{0}", this.props.command)}
            </li>
            <li className="result">{this.userData.noCommandL2}</li>
          </ul>
        )}
      </React.Fragment>
    );
  }
}
