import * as React from "react";
import { isIos } from "../../../utils/device";
import $ from "jquery";

declare global {
  interface Window {
    blinkInterval: any;
  }
}

export interface INewLineProps {
  onKeyDown: (command: string, Tid: number) => void;
  command: {
    command: string;
    id?: number;
    displayInput?: boolean;
    absoluteCommand: string;
  };
  displayInput?: boolean;
}

interface INewLineState {
  id: number;
  command: string;
}

export class NewLine extends React.Component<INewLineProps, INewLineState> {
  textInput: React.RefObject<HTMLInputElement>;
  constructor(props: INewLineProps) {
    super(props);
    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
    this.state = {
      id: this.props.command.id as number,
      command: this.props.command.command,
    };
  }

  focusTextInput() {
    if (this.textInput.current) this.textInput.current.focus();
  }

  componentDidMount = () => {
    $("input").on(
      "ready rendered change blur mouseup focus keydown keyup",
      function (evt: { target: any }) {
        var $el = $(evt.target);
        if (!$el.css("caret-color")) return;
        var caretIndex = $el[0].selectionStart;
        var textBeforeCarret = ($el.val() as string).substring(0, caretIndex);

        var bgr = getBackgroundStyle($el, textBeforeCarret);
        $el.css("background", bgr);
        clearInterval(window.blinkInterval);
        window.blinkInterval = setInterval(blink, 600);
      }
    );

    function blink() {
      $("input").each((_index: any, el: any) => {
        var $el = $(el);
        if ($el.css("background-blend-mode") !== "normal") {
          $el.css("background-blend-mode", "normal");
        } else {
          $el.css("background-blend-mode", "color-burn");
        }
      });
    }

    function getBackgroundStyle($el: any, text: any) {
      var factor = isIos() ? 0.82 : 0.12;
      var fontSize = $el.css("font-size");
      var fontFamily = $el.css("font-family");

      var font = fontSize + " " + fontFamily;
      var canvas = $el.data("carretCanvas");
      if (canvas == null) {
        canvas = document.createElement("canvas");
        $el.data("carretCanvas", canvas);
        var ctx = canvas.getContext("2d");
        ctx.font = font;
        ctx.strokeStyle = "#fff";
        ctx.lineWidth = Math.ceil(parseInt(fontSize) / 5);
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(parseInt(fontSize) / 2, 0);
        ctx.stroke();
      }
      var offsetLeft =
        canvas.getContext("2d").measureText(text).width +
        parseInt($el.css("padding-left"));
      return (
        "#fff url(" +
        canvas.toDataURL() +
        ") no-repeat " +
        (offsetLeft -
          $el.scrollLeft() +
          (parseInt($el.css("letter-spacing")) + factor) * $el.val().length) +
        "px " +
        ($el.height() + (parseInt($el.css("padding-top")) - 4)) +
        "px"
      );
    }
  };

  getClassName() {
    let className = "prompt ";
    className += this.props.displayInput ? "output new-output" : "";
    return className;
  }

  handleChange = (event: any) => {
    this.setState({ command: event.target.value });
  };

  onKeyDown = (event: any) => {
    if (event.key === "Enter") {
      if (event.target.value.trim() !== "newLine")
        this.props.onKeyDown(event.target.value, this.state.id);
      else
        this.props.onKeyDown(`${event.target.value.trim()}$|$`, this.state.id);
    }
    //TODO implement this
    if (event.key === "ArrowUp") {
      this.setState({ command: "yo" });
    }
  };

  render(): JSX.Element {
    return (
      <React.Fragment>
        <p className={this.getClassName()}>
          {this.props.displayInput && (
            <input
              ref={this.textInput}
              type="text"
              className="here"
              value={this.state.command}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
              autoFocus
              maxLength={30}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              onBlur={this.focusTextInput.bind(this)}
            />
          )}
        </p>
      </React.Fragment>
    );
  }
}
