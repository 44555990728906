import * as React from "react";
import commandMenu from "../../../data/commandMenu.json";
import { ICommandMenu } from "../../../model/commandMenu";

export class Help extends React.Component {
  commandMenu: ICommandMenu;

  constructor(props: {}) {
    super(props);

    this.commandMenu = commandMenu;
  }

  render(): JSX.Element {
    return (
      <React.Fragment>
        <p className="prompt">{"help"}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: this.commandMenu.generalInfo,
          }}
          className="result"
        />
        <p>
          {this.commandMenu.availableCommands.map((item) => (
            <p key={item.id} className="result m-t-0 m-b-0">
              {item.name}
            </p>
          ))}
        </p>
      </React.Fragment>
    );
  }
}
